import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import Img from "gatsby-image"
import Layout from "../components/layout"
//
import SEO from "../components/seo"
import SubscribtionForm from "../components/subscribtionForm"

const Post = tw.main`
max-w-2xl mx-auto p-2 mt-4 pb-24
`

const Title = tw.h2`
w-full text-3xl font-sans font-black text-darkgrey leading-tight
`

const Date = tw.p`
w-full mt-4 text-sm font-sans text-darkgrey
`

const Section =
  "prose text-darkgrey font-serif font-normal text-lg md:text-base"

const CardPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const SubscribtionFormDiv = (
    <div className="mt-6 mb-6">
      <SubscribtionForm />
    </div>
  )
  const Header = (
    <header>
      <Date>{post.frontmatter.number}</Date>
      <Title>{post.frontmatter.title}</Title>
    </header>
  )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Post>
        <article>
          <Img fluid={post.frontmatter.card.childImageSharp.fluid} />
          {Header}
          {SubscribtionFormDiv}
          <section
            className={Section}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>
      </Post>
    </Layout>
  )
}

export default CardPostTemplate

export const pageQuery = graphql`
  query CardPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        number
        card {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
